import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routesMeta from '../routesMeta.js'; // Importing JavaScript file

const SetPageMetadata = () => {
  const location = useLocation();
  
  useEffect(() => {
    const path = location.pathname;
    const meta = routesMeta[path];

    if (meta) {
      // Set the page title
      document.title = meta.title || "Neuronurt";

      // Set the meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', meta.description || "Default description for Neuronurt.");
      }

      // Set the canonical URL
      let link = document.querySelector("link[rel='canonical']");
      if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
      }
      link.setAttribute('href', meta.canonical || window.location.href);
    }
  }, [location]);

  return null;
};

export default SetPageMetadata;
