import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Footer from './Footer';

const LandingLayout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Product', href: '/product' },
    { name: 'Partner', href: '/partner' },
    { name: 'Case Studies', href: '/case-studies' },
    { name: 'Contact', href: '/contact' },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-6 flex items-center justify-between">
            <div className="flex items-center">
              <Link to="/">
                <span className="sr-only">Neuronurt</span>
                <img
                  className="h-12 w-auto"
                  src="/assets/logo.png"
                  alt="NeuroNurtr Logo - Empowering Early Childhood Development"
                />
              </Link>
              <div className="hidden ml-10 space-x-8 lg:block">
                {navigation.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`text-base font-medium ${
                      location.pathname === link.href
                        ? 'text-[#da4769]'
                        : 'text-gray-500 hover:text-gray-900'
                    }`}
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="ml-10 space-x-4">
              <Link
                to="/request-demo"
                className="inline-block bg-[#da4769] py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-[#c13d5d] transition duration-150 ease-in-out"
              >
                Request Demo
              </Link>
              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6 text-gray-400" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6 text-gray-400" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className={`block px-3 py-2 rounded-md text-base font-medium ${
                    location.pathname === link.href
                      ? 'text-[#da4769] bg-gray-50'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default LandingLayout;