// routesMeta.js

const getBaseUrl = () => {
    // Use production URL for production, and development URL for dev environments
    return process.env.NODE_ENV === 'production' 
      ? 'https://neuronurtr.com' 
      : 'https://website.neuronurtr.com';
  };
  
  const baseUrl = getBaseUrl();
  
  const routesMeta = {
    "/": {
      "title": "NeuroNurtr - Home",
      "description": "Welcome to NeuroNurtr, your go-to platform for brain health.",
      "canonical": `${baseUrl}/`
    },
    "/about": {
      "title": "About NeuroNurtr - Our Mission to Support Child Development",
      "description": "Learn about NeuroNurtr's journey and commitment to early childhood development.",
      "canonical": `${baseUrl}/about`
    },
    "/product": {
      "title": "NeuroNurtr ATS - Comprehensive Child Development Tool",
      "description": "Discover NeuroNurtr's ATS platform that helps track child development.",
      "canonical": `${baseUrl}/product`
    },
    "/partner": {
      "title": "Partner with NeuroNurtr - Join Us in Supporting Child Development",
      "description": "Collaborate with NeuroNurtr and enhance your organization’s ability to support child development.",
      "canonical": `${baseUrl}/partner`
    },
    "/contact": {
      "title": "Contact NeuroNurtr - Get in Touch",
      "description": "Contact NeuroNurtr for questions, partnerships, or support.",
      "canonical": `${baseUrl}/contact`
    },
    "/privacy": {
      "title": "NeuroNurtr Privacy Policy - Protecting Your Data",
      "description": "Learn how NeuroNurtr handles your data and privacy.",
      "canonical": `${baseUrl}/privacy`
    },
    "/terms": {
      "title": "NeuroNurtr Terms of Service",
      "description": "Understand the terms of using NeuroNurtr’s Assessment Tool System and website.",
      "canonical": `${baseUrl}/terms`
    },
    "/faq": {
      "title": "NeuroNurtr FAQ - Frequently Asked Questions",
      "description": "Find answers to common questions about NeuroNurtr and its services.",
      "canonical": `${baseUrl}/faq`
    },
    "/request-demo": {
      "title": "Request a Demo of NeuroNurtr ATS",
      "description": "See NeuroNurtr's ATS in action by scheduling a demo.",
      "canonical": `${baseUrl}/request-demo`
    },
    "/case-studies": {
      "title": "NeuroNurtr Case Studies - Success Stories",
      "description": "Learn how organizations are leveraging NeuroNurtr's ATS to support child development.",
      "canonical": `${baseUrl}/case-studies`
    },
    "/research": {  
      "title": "In-Depth Research on Child Development and Screening Tools - NeuroNurtr",
      "description": "Discover comprehensive research papers focused on early identification, developmental screening, autism, and assessment tools for child development, with a focus on innovative solutions and policy recommendations.",
      "canonical": `${baseUrl}/research`
    }
  };
  
  export default routesMeta;
  