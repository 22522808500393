import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
    { name: 'FAQs', href: '/faq' },
    { name: 'Research Paper', href: '/research' },
    { name: 'Contact Us', href: '/contact' },
  ];

  return (
    <footer className="bg-white py-6 border-t border-gray-200">
      <div className="container mx-auto px-4 flex flex-col items-center">
        <div className="mb-4">
          <img src="/assets/logo_gray.png" alt="Neuronurtr Symbol" className="h-12 w-auto" />
        </div>
        <div className="text-center">
          <div className="mb-2">
            {footerLinks.map((link, index) => (
              <React.Fragment key={link.name}>
                <Link to={link.href} className="text-sm text-gray-600 hover:text-[#da4769] transition duration-150 ease-in-out">
                  {link.name}
                </Link>
                {index < footerLinks.length - 1 && <span className="mx-2 text-gray-400">|</span>}
              </React.Fragment>
            ))}
          </div>
          <p className="text-sm text-gray-500">
            Copyright © {currentYear} NeuroNurtr. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;