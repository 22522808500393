import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingLayout from './layouts/LandingLayout';
import Loader from './components/Loader';
import SetPageMetadata from './utils/SetPageMetadata';

// Lazy load the components
const HomePage = lazy(() => import('./components/HomePage'));
const AboutUsPage = lazy(() => import('./components/AboutUsPage'));
const ProductDetailPage = lazy(() => import('./components/ProductDetailPage'));
const PartnerWithUsPage = lazy(() => import('./components/PartnerWithUsPage'));
const ContactUsPage = lazy(() => import('./components/ContactUsPage'));
const FAQPage = lazy(() => import('./components/FAQPage'));
const RequestDemoPage = lazy(() => import('./components/RequestDemoPage'));
const CaseStudiesPage = lazy(() => import('./components/CaseStudies'));
const PrivacyPolicyPage = lazy(() => import('./components/LegalPage').then(module => ({ default: module.PrivacyPolicyPage })));
const TermsOfServicePage = lazy(() => import('./components/LegalPage').then(module => ({ default: module.TermsOfServicePage })));
const ResearchPaperPage = lazy(() => import('./components/ResearchPaperPage'));


function App() {
  return (
    <Router>
      <SetPageMetadata />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<LandingLayout><HomePage /></LandingLayout>} />
          <Route path="/about" element={<LandingLayout><AboutUsPage /></LandingLayout>} />
          <Route path="/product" element={<LandingLayout><ProductDetailPage /></LandingLayout>} />
          <Route path="/partner" element={<LandingLayout><PartnerWithUsPage /></LandingLayout>} />
          <Route path="/contact" element={<LandingLayout><ContactUsPage /></LandingLayout>} />
          <Route path="/privacy" element={<LandingLayout><PrivacyPolicyPage /></LandingLayout>} />
          <Route path="/terms" element={<LandingLayout><TermsOfServicePage /></LandingLayout>} />
          <Route path="/faq" element={<LandingLayout><FAQPage /></LandingLayout>} />
          <Route path="/request-demo" element={<LandingLayout><RequestDemoPage /></LandingLayout>} />
          <Route path="/case-studies" element={<LandingLayout><CaseStudiesPage /></LandingLayout>} />
          <Route path="/research" element={<LandingLayout><ResearchPaperPage /></LandingLayout>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
